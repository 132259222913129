import React from "react";
import styles from './Top.module.css';
import img1 from '../../../assets/aboutUs/whatwedo.jpg';
import img2 from '../../../assets/aboutUs/img2.jpg';

const Top = () => {
    return (
        <div className={`container overflow-hidden`}>
            <p data-aos='slide-right' className={styles.heading}>ABOUT VYNX IT</p>
            <p data-aos='slide-left' className={styles.subHeading}>Innovative Development Solutions</p>
            <p data-aos='fade-up' data-aos-offset='80' className={styles.content}>
                VYNX IT is a leading provider of innovative web development solutions, committed to delivering high-quality services tailored to meet our clients' needs. Our team of experts combines creativity with the latest technologies to create user-friendly and efficient web applications. We focus on providing exceptional service and support, ensuring that every project exceeds expectations.
            </p>
        
            <div className={`row ${styles.contain} flex-column-reverse flex-md-row`}>
                <div data-aos='slide-right' className={`col-md-6`}>
                    <p className={styles.about}>What We Do</p>
                    <p className={styles.creativity}>Creating Value Through Technology</p>
                    <p className={styles.contn}>
                        At VYNX IT, we specialize in developing custom solutions that empower businesses to thrive in the digital age. Our services include:
                        <ul>
                            <li>Custom Web Development</li>
                            <li>Android Development</li>
                            <li>Digital Marketing</li>
                            <li>IT Consultation</li>
                            <li>Cloud Solutions</li>
                        </ul>
                        We pride ourselves on our ability to adapt to the ever-changing technology landscape, ensuring our clients stay ahead of the competition.
                    </p>
                </div>
                <div data-aos='slide-left' data-aos-offset='50' className={`col-md-6 ${styles.imgContain}`}>
                    <img src={img1} alt="VYNX IT Team" className={styles.img1} />
                </div>
            </div>
            <div data-aos='fade-up' className='row' style={{ textAlign: 'left' }}>
                <div className={`col-md-6`}>
                    <img src={img2} alt="Our Projects" className={styles.img2} />
                </div>
            </div>
        </div>
    )
}

export default Top;
