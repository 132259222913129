import React from "react";
import styles from "./Middle.module.css";
import logo1 from "../../../assets/aboutUs/middle1.png";
import logo2 from "../../../assets/aboutUs/middle2.png";
import logo3 from "../../../assets/aboutUs/middle3.png";

const Middle = () => {
  return (
    <div style={{ backgroundColor: "#f8f8f8" }} className="overflow-hidden">
      <div className={`container ${styles.contain}`}>
        <p
          data-aos="fade-down-right"
          data-aos-offset="170"
          className={styles.mainHead}
        >
          Company Overview
        </p>
        <p
          data-aos="fade-up-left"
          data-aos-offset="170"
          className={styles.mainContent}
        >
          At VYNX Information Technology, we strive to deliver exceptional IT
          solutions that empower businesses to thrive in the digital age.
        </p>

        <div className="row">
          <div data-aos="fade-right" data-aos-offset="170" className="col-md-4">
            <div className={styles.box}>
              <img src={logo1} alt="Mission Statement" />
              <p className={styles.head}>Mission Statement</p>
              <p className={styles.content}>
                Our mission is to provide innovative and reliable IT services
                that drive success for our clients and contribute to the growth
                of the technology ecosystem.
              </p>
            </div>
          </div>

          <div data-aos="fade-up" className="col-md-4">
            <div className={styles.box}>
              <img src={logo3} alt="Core Values" />
              <p className={styles.head}>Vision Statement</p>
              <p className={styles.content}>
                We envision a future where technology integrates with
                everyday life, enabling to achieve their full
                potential through digital transformation.
              </p>
            </div>
          </div>

          <div data-aos="fade-left" className="col-md-4">
            <div className={styles.box}>
              <img src={logo2} alt="Vision Statement" />
              <p className={styles.head}>Core Values</p>
              <p className={styles.content}>
                We are committed to integrity, innovation, and excellence,
                ensuring that we always act in the best interest of our clients
                and community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Middle;
