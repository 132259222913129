import Cs from "../../assets/home/cs.jpg";
import web from "../../assets/home/web.jpg";
import android from "../../assets/home/android.jpg";
import Dm from "../../assets/home/dm.jpg";
import It from "../../assets/home/IT.jpg";
import dev from "../../assets/home/developer.jpg";

const data = [
  {
    id: 1,
    heading: "Web Development",
    content:
      "We provide web development services using technologies such as ReactJS, AngularJS, NodeJS, HTML5, CSS, and JavaScript.",
    to: "/webAndroidDevelopment",
    icon: web,
  },
  {
    id: 2,
    heading: "Digital Marketing",
    content:
      "We can help you with Digital Marketing, Search Engine Optimization, and Email Marketing.",
    to: "/sMediaService",
    icon: Dm,
  },
  {
    id: 3,
    heading: "Mobile Application Development",
    content:
      "We provide  high-performance Android and iOS apps using Flutter, ensuring a seamless user experience across devices.",
    to: "/webAndroidDevelopment",
    icon: android,
  },
  {
    id: 4,
    heading: "IT Consulting",
    content:
      "Expert advice to help you leverage technology for your business growth.",
    to: "/",
    icon: It,
  },
  {
    id: 5,
    heading: "Cloud Solutions",
    content: "Scalable and secure cloud services for your business operations.",
    to: "/",
    icon: Cs,
  },
  {
    id: 6,
    heading: "Custom Software Development",
    content: "Tailored applications that fit your unique business needs.",
    to: "/",
    icon: dev,
  },
];

export default data;
