import image1 from "../../assets/home/testimonial1.png";
import image2 from "../../assets/home/testimonial2.png";
import image3 from "../../assets/home/testimonial2.png";

export const fakeData = [
  {
    id: 1,
    img: image1,
    name: "Howard Arlene",
    profession: "Finance Manager",
    message:
      "Your company is truly upstanding and is behind its product 100%. It's the perfect solution for our business. It has really helped our business.",
    rating: 5,
  },
  {
    id: 2,
    img: image2,
    name: "Miles Esther",
    profession: "Public Relations Specialist",
    message:
      "You won't regret it. I was amazed at the quality of it. I am really satisfied with it.",
    rating: 4,
  },
  {
    id: 3,
    img: image3,
    name: "Pena Norma",
    profession: "Marketing Specialist",
    message:
      "It fits our needs perfectly. Man, this thing is getting better and better as I learn more about it. You've saved our business!",
    rating: 5,
  },
  {
    id: 4,
    img: image1,
    name: "Ernest Daniels",
    profession: "Finance Analyst",
    message:
      "The service is outstanding. It was the perfect solution for our business needs.",
    rating: 4,
  },
  {
    id: 5,
    img: image2,
    name: "Linda Carter",
    profession: "Public Relations Manager",
    message:
      "The product quality is impressive, and the support has been phenomenal.",
    rating: 5,
  },
  {
    id: 6,
    img: image3,
    name: "Oscar Williams",
    profession: "Business Analyst",
    message:
      "The system has streamlined our operations and improved our efficiency!",
    rating: 5,
  },
  {
    id: 7,
    img: image1,
    name: "Nina Watson",
    profession: "Project Manager",
    message:
      "The matrimony website developed by VYNX has exceeded our expectations, providing a seamless experience for our users!",
    rating: 5,
  },
  {
    id: 8,
    img: image2,
    name: "Jacob Stewart",
    profession: "App Developer",
    message:
      "We are thrilled with the Flutter application VYNX built for us. It's user-friendly and performs flawlessly.",
    rating: 5,
  },
  {
    id: 9,
    img: image3,
    name: "Monica Green",
    profession: "Travel Agent",
    message:
      "The tours and travel website designed by VYNX has greatly improved our customer engagement and bookings.",
    rating: 5,
  },
  {
    id: 10,
    img: image1,
    name: "Brian Cooper",
    profession: "Business Strategist",
    message:
      "VYNX’s expertise in business development strategies helped us to scale efficiently and effectively.",
    rating: 5,
  },
  {
    id: 11,
    img: image2,
    name: "Samantha Lee",
    profession: "Real Estate Agent",
    message:
      "The real estate website developed by VYNX offers an amazing user experience and has increased our property inquiries.",
    rating: 5,
  },
  {
    id: 12,
    img: image3,
    name: "Evelyn Adams",
    profession: "Marketing Manager",
    message:
      "VYNX Information Technology transformed our online presence with their outstanding web development services!",
    rating: 5,
  },
  {
    id: 13,
    img: image1,
    name: "Charles Harris",
    profession: "Operations Manager",
    message:
      "The IT consulting provided by VYNX helped us streamline our operations and improve efficiency.",
    rating: 4,
  },
  {
    id: 14,
    img: image2,
    name: "Lucas Murphy",
    profession: "Cloud Architect",
    message:
      "Their cloud solutions are reliable and scalable. Highly recommended for any business!",
    rating: 5,
  },
  {
    id: 15,
    img: image3,
    name: "Emily Russell",
    profession: "Digital Marketing Specialist",
    message:
      "Thanks to VYNX's digital marketing strategies, our visibility has increased significantly!",
    rating: 4,
  },
  {
    id: 16,
    img: image1,
    name: "Adam King",
    profession: "Software Engineer",
    message:
      "The custom software solutions provided by VYNX have been instrumental in automating our processes.",
    rating: 5,
  },
  {
    id: 17,
    img: image2,
    name: "Sophia Torres",
    profession: "Product Designer",
    message:
      "We appreciate the innovative approach VYNX takes to problem-solving.",
    rating: 5,
  },
  {
    id: 18,
    img: image3,
    name: "David Robinson",
    profession: "IT Consultant",
    message:
      "Their expertise in IT consulting helped us navigate complex challenges effortlessly.",
    rating: 4,
  },
];
