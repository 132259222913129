import It from "../../assets/individualService/It.jpg";
import IT1 from "../../assets/individualService/It1.jpg";
import cs from "../../assets/individualService/CLOUD.jpg";
import cs1 from "../../assets/individualService/CLOUD1.jpg";
import sd from "../../assets/individualService/Coustem.jpg";
import sd1 from "../../assets/individualService/Coustem1.jpg";

const data = [
  {
    id: 1,
    heading: "Transform Your Business with Technology",
    content:
      "Leverage cutting-edge technologies to enhance efficiency and drive growth. Our expert team specializes in tailored IT solutions that empower your business to adapt and thrive in a competitive landscape.",
    contents: [
      {
        heading: "Solutions for Growth",
        img: It,
        content:
          "We provide personalized IT solutions that align with your business goals. Our comprehensive assessments and strategic development ensure that your systems are optimized for sustainable growth and continuous innovation.",
      },
      {
        heading: "Strategic Planning",
        img: IT1,
        content:
          "Partner with us to craft a robust IT strategy that not only meets your current needs but also prepares you for future challenges. We analyze your existing technology, assess risks, and create a roadmap for a successful digital transformation.",
      },
    ],
  },

  {
    id: 2,
    heading: "Cloud Solutions",
    content:
      "Scalable and secure cloud services for your business operations. Our cloud solutions enhance flexibility and collaboration while improving operational efficiency and reducing costs.",
    contents: [
      {
        heading: "Cloud Migration",
        img: cs,
        content:
          "We facilitate smooth migrations to cloud platforms, ensuring that your business experiences minimal downtime. Our experienced team manages the entire process, from meticulous planning to data backup and seamless implementation.",
      },
      {
        heading: "Managed Cloud Services",
        img: cs1,
        content:
          "With our managed cloud services, you receive ongoing support tailored to your specific requirements. We ensure your cloud environment operates at peak efficiency, with 24/7 monitoring, timely updates, and security enhancements to protect your data.",
      },
    ],
  },

  {
    id: 3,
    heading: "Custom Software Development",
    content:
      "Our custom software development services are tailored to meet your unique business needs, providing innovative solutions that drive efficiency and growth. We follow a collaborative approach to understand your requirements and create software that enhances your business processes.",
    contents: [
      {
        heading: "Bespoke Applications",
        img: sd,
        content:
          "We design bespoke applications tailored to your operational needs, ensuring that they enhance productivity and align with your workflows. Our applications are built using the latest technologies, offering scalability and a user-friendly experience.",
      },
      {
        heading: "Business Growth",
        img: sd1,
        content:
          "Our software solutions are crafted to streamline your business processes. By harnessing advanced technologies, we create applications that improve functionality, automate tasks, and ultimately enhance your operational efficiency and growth trajectory.",
      },
    ],
  },
];

export default data;
