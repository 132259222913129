import React, { useState, useEffect } from "react";
import styles from "./Banner.module.css";

import it from "../../assets/it1.jpg";
import it1 from "../../assets/it.jpg";
import it2 from "../../assets/it3.jpg";
import it3 from "../../assets/it5.jpg";
import it4 from "../../assets/it6.jpg";

const Banner = () => {
  const images = [it, it1, it2, it3, it4];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); 
    return () => clearInterval(interval); 
  }, []);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className={styles.imgbox} style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <div className={`${styles.center} overflow-hidden`}>
        <p data-aos="fade-down" className={styles.text}>
          VYNX Information Technology LLC.
        </p>
        <p data-aos="fade-down" className={styles.subText}>
          Your trusted partner for business growth through innovative web
          solutions!
        </p>
      </div>
      <span
        aria-hidden="true"
        className={`${styles.carouselControl} ${styles["carousel-control-prev"]}`}
        onClick={prevImage}
      >
        &#10094;
      </span>
      <span
        aria-hidden="true"
        className={`${styles.carouselControl} ${styles["carousel-control-next"]}`}
        onClick={nextImage}
      >
        &#10095;
      </span>
    </div>
  );
};

export default Banner;
