import React, { useState, useEffect } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import styles from "./NavbarMain.module.css";
import { NavLink, Link } from "react-router-dom";

const NavbarMain = () => {
  const [activeNav, setActiveNav] = useState([true, false, false, false]);
  const [expand, setExpand] = useState(false);

  const closeNav = () => setExpand(false);

  useEffect(() => {
    const storedNavState = sessionStorage.getItem("NavbarMain");
    if (storedNavState) {
      setActiveNav(JSON.parse(storedNavState));
    }
  }, []);

  const handleActiveNav = (index) => {
    const updatedNav = activeNav.map((_, i) => i === index);
    setActiveNav(updatedNav);
    sessionStorage.setItem("NavbarMain", JSON.stringify(updatedNav));
  };

  const handleLogoClick = () => {
    window.location.href = "https://vynxgroup.com/";
  };

  return (
  <Navbar
  style={{
    backgroundColor: "white",
    boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.4)",
  }}
  variant="light"
  expand="lg"
  sticky="top"
  onToggle={() => setExpand(prev => !prev)}
  expanded={expand}
>
  <Container>
    <div className={styles.logoContainer}>
      <img
        src={require("../../assets/Company logo.jpg")}
        alt="VYNX Logo"
        className={styles.logoImage}
        onClick={handleLogoClick}  
        style={{ cursor: "pointer" }}  
      />
      <Navbar.Brand
        href="https://vynxgroup.com/"
        className={styles.logo}
        style={{ cursor: "pointer" }}
      >
        VYNX
        <div className={styles.subLogo}>Information Technology LLC</div>
      </Navbar.Brand>
    </div>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav style={{ marginLeft: "auto" }}>
        {["/", "/aboutUs", "/services", "/contactUs"].map((path, index) => (
          <NavLink
            key={index}
            to={path}
            className={`${styles.nav_text} nav-link ${activeNav[index] ? styles.active : ""}`}
            style={{ marginTop: "8px" }}
            onClick={() => {
              handleActiveNav(index);
              closeNav();
            }}
          >
            {["Home", "About us", "Services", "Contact Us"][index]}
          </NavLink>
        ))}
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
  );
};

export default NavbarMain;