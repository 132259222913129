import React from "react";
import styles from "./ServiceHome.module.css";

const ServiceHome = (props) => {
  return (
    <div className={`${styles.box} user-select-none`}>
      <img src={props.icon} alt="" className={styles.img} />
      <p className={styles.cardHeading}>{props.heading}</p>
      <p className={styles.cardText}>{props.content}</p>
      {/* Uncomment if you want the link back in */}
      {/* <Link to={`/individualService?id=${props.id}`}>
        <HiOutlineArrowNarrowRight className={styles.to} size="2rem" color="#333" />
      </Link> */}
    </div>
  );
};

export default ServiceHome;
