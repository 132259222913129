import web from "../../assets/home/web.jpg";
import seo1 from "../../assets/individualService/Image.png";
import seo2 from "../../assets/individualService/Image_2.png";
import cs from "../../assets/home/cs.jpg";
import android from "../../assets/home/android.jpg";
import dm from "../../assets/home/dm.jpg";
import it from "../../assets/home/IT.jpg";
import dev from "../../assets/home/developer.jpg";

const data = [
  {
    id: 1,
    heading: "Web Development",
    content:
      "We provide web development services using technologies such as ReactJS, AngularJS, NodeJS, HTML5, CSS, and JavaScript.",
    to: "/webAndroidDevelopment",
    icon: web,
    contents: [
      {
        heading: "Get The Maximum Benefits",
        img: seo1,
        content: "",
      },
      {
        heading: "Get The Maximum Benefits",
        img: seo2,
        content: "",
      },
    ],
  },
  {
    id: 2,
    heading: "Digital Marketing",
    content:
      "We can help you with Digital Marketing, Search Engine Optimization, and Email Marketing.",
    to: "/sMediaService",
    icon: dm,
    contents: [
      {
        heading: "Get The Maximum Benefits",
        img: seo1,
        content: "",
      },
      {
        heading: "Get The Maximum Benefits",
        img: seo2,
        content: "",
      },
    ],
  },
  {
    id: 3,
    heading: "Mobile Application Development",
    content:
      "We provide  high-performance Android and iOS apps using Flutter, ensuring a seamless user experience across devices.",
    to: "/webAndroidDevelopment",
    icon: android,
    contents: [
      {
        heading: "Get The Maximum Benefits",
        img: seo1,
        content: "",
      },
      {
        heading: "Get The Maximum Benefits",
        img: seo2,
        content: "",
      },
    ],
  },
  {
    id: 4,
    heading: "IT Consulting",
    content:
      "Expert advice to help you leverage technology for your business growth.",
      to: "/individualService?id=1",
    icon: it,
    contents: [
      {
        heading: "Get The Maximum Benefits",
        img: seo1,
        content: "",
      },
      {
        heading: "Get The Maximum Benefits",
        img: seo2,
        content: "",
      },
    ],
  },
  {
    id: 5,
    heading: "Cloud Solutions",
    content: "Scalable and secure cloud services for your business operations.",
    to: "/individualService?id=2",
    icon: cs,
    contents: [
      {
        heading: "Get The Maximum Benefits",
        img: seo1,
        content: "",
      },
      {
        heading: "Get The Maximum Benefits",
        img: seo2,
        content: "",
      },
    ],
  },
  {
    id: 6,
    heading: "Custom Software Development",
    content: "Tailored applications that fit your unique business needs.",
    to: "/individualService?id=3",
    icon: dev,
    contents: [
      {
        heading: "Get The Maximum Benefits",
        img: seo1,
        content: "",
      },
      {
        heading: "Get The Maximum Benefits",
        img: seo2,
        content: "",
      },
    ],
  },
];

export default data;
