import React from "react";
import Footer from "../../components/Footer";
import WebAndroidDevelopment from '../../components/WebAndroidDevelopment/WebAndroidDevelopment';

const webAndroidDevelopmentPage = () => {
  return (
    <>
      {/* Main Section */}
      <section>
        <WebAndroidDevelopment />
      </section>

      {/* Footer Section */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default webAndroidDevelopmentPage;
