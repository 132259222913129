import React from "react";
import { Link } from "react-router-dom"; // Import Link
import styles from "./AboutUsHome.module.css";
import img1 from "../../assets/home/solutions.jpg";
import img2 from "../../assets/home/team.jpg";
import img3 from "../../assets/home/latest tech.jpg";

const AboutUsHome = () => {
  return (
    <>
      <div className={`container ${styles.contain}`}>
        <div className="row overflow-hidden">
          <div className="col-md-6">
            <p data-aos="slide-right" className={styles.head}>
              ABOUT US
            </p>
            <p data-aos="slide-left" className={styles.heading}>
              We have a great team in terms of technology
            </p>
            <div data-aos="zoom-in">
              <p className={styles.content}>
                Our company has a highly specialized management team with over
                10 years of experience in the software industry. We are
                committed to delivering top-notch IT solutions through our
                experienced developers who bring extensive expertise in the
                field. By implementing the latest technologies, we ensure
                innovative solutions that keep us ahead of the curve. Our
                client-centered approach allows us to tailor every solution to
                meet the unique needs of our clients, while our rigorous quality
                assurance processes guarantee the highest standards. We also
                pride ourselves on offering competitive pricing without
                compromising quality, ensuring great value for our clients.
                Moreover, our commitment to client success continues beyond
                launch with ongoing support and maintenance, and we specialize
                in developing customized solutions that align with your business
                goals and objectives.
              </p>
            </div>
            <div data-aos="zoom-in" style={{ textAlign: "left" }}>
              <Link to="/aboutUs">
                <button className={`btn custom_btn ${styles.btn}`}>
                  Know More
                </button>
              </Link>
            </div>
          </div>

          <div className="col-md-6 mt-5 overflow-hidden">
            <div className={styles.row}>
              <div className={`${styles.column} ${styles.col1}`}>
                <img data-aos="zoom-in-right" src={img1} alt="" />
              </div>
              <div className={styles.column}>
                <img
                  data-aos="zoom-in-left"
                  src={img2}
                  style={{ width: "110%" }}
                  alt=""
                />
                <img data-aos="zoom-in-left" src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsHome;
