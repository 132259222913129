import React from "react";
import styles from "./SMediaServices.module.css";
import img1 from "../../assets/sMediaService/main.png";
import img2 from "../../assets/sMediaService/middle1.png";
import img3 from "../../assets/sMediaService/bottom1.png";

const SMediaServices = () => {
  return (
    <>
      <div>
        {/* Top */}
        <div className="row mt-5 mx-0 flex-column-reverse flex-md-row">
          <div className="col-md-6 px-0">
            <div className={styles.topContain}>
              <p className={`${styles.headTop}`}>
              <span style={{ color: "#9951c4" }}>Social Media Marketing</span>
              </p>
              <p className={styles.identityTop}>Identity on Social Media</p>
              <p className={styles.content}>
                In today’s digital landscape, your brand's social media presence
                is essential to standing out and connecting with customers. VYNX
                crafts a unique, trustworthy, and engaging online identity that
                resonates with your audience. From developing a consistent brand
                voice to designing custom visuals and running targeted
                campaigns, we build connections that go beyond
                clicks—cultivating loyalty and genuine engagement. Our
                data-driven approach ensures your brand's message reaches the
                right people at the right time, making every interaction
                meaningful and impactful. Let VYNX guide your brand to thrive on
                social media, turning followers into advocates and engagement
                into lasting growth.
              </p>
            </div>
          </div>
          <div className="col-md-6 px-0">
            <img src={img1} alt="Main Social Media" className={styles.img1} />
          </div>
        </div>

        {/* Middle */}
        <div className="container">
          <div
            className={`row ${styles.contain} flex-column-reverse flex-md-row`}
          >
            <div className={`col-md-6 ${styles.middleContain}`}>
              <p className={styles.heading}>Next Level Engagement</p>
              <p className={styles.text}>
                Social media has become an essential part of the business
                landscape. Our team not only brings your brand into the social
                media arena but also elevates it, fostering meaningful
                connections with customers. Leverage our expertise to build
                trust, drive engagement, and open a direct channel to your
                audience.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={img2}
                alt="Middle Social Media"
                className={styles.img2}
              />
            </div>
          </div>
        </div>

        {/* Bottom */}
        <div className="container">
          <div className={`row ${styles.contain}`}>
            <div className="col-md-6">
              <img src={img3} alt="Custom Approach" className={styles.img2} />
            </div>
            <div className={`col-md-6 ${styles.bottomContain}`}>
              <p className={styles.heading1}>
                A Custom Approach for Your Brand
              </p>
              <p className={styles.text1}>
                Every business is unique, and we believe your social media
                strategy should reflect that. Our team works with you to create
                custom-designed graphics and content that captures your brand’s
                voice. With a strong focus on quality and aesthetics, we ensure
                your brand shines across platforms like Facebook, Twitter, and
                Instagram, driving traffic and engagement straight to your site.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMediaServices;
