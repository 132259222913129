import React from "react";
import styles from "./WebAndroidDevelopment.module.css";
import img1 from "../../assets/WebAndroidDevelopment/webdevelopment.jpg";
import img2 from "../../assets/WebAndroidDevelopment/Mobiledevelopment.jpg";
import img3 from "../../assets/WebAndroidDevelopment/business.jpg";
import img4 from "../../assets/WebAndroidDevelopment/Csoftware.jpg";
import { FaCode, FaMobileAlt, FaTools, FaCogs } from "react-icons/fa"; // Import icons

const WebAndroidDevelopment = () => {
  return (
    <>
      <div className={styles.container}>
        {/* Web Development Section */}
        <div
          className={`${styles.section} row mt-5 mx-0 flex-column-reverse flex-md-row`}
        >
          <div className="col-md-6 px-0">
            <div className={styles.topContain}>
              <p className={`${styles.headTop}`}>
                <span style={{ color: "#9951c4" }}>
                  <FaCode /> Web Development
                </span>
              </p>
              <p className={styles.headTop}>Services</p>
              <p className={styles.identityTop}>
                Your Digital Solutions Partner
              </p>
              <p className={styles.content}>
                In the fast-paced digital world, having a robust web presence is
                crucial for businesses to thrive. At VYNX, we specialize in
                developing dynamic websites tailored to meet your specific
                needs. We provide web development services using cutting-edge
                technologies such as ReactJS, AngularJS, NodeJS, HTML5, CSS3,
                and JavaScript. Our expert developers create responsive,
                user-friendly, and high-performing solutions designed to enhance
                user experience and drive growth...
              </p>
            </div>
          </div>
          <div className="col-md-6 px-0">
            <img
              src={img1}
              alt="Web Development"
              className={`${styles.img} ${styles.img1}`}
            />
          </div>
        </div>

        {/* Mobile Application Development Section */}
        <div
          className={`${styles.section} row mt-5 mx-0 flex-column-reverse flex-md-row`}
        >
          <div className="col-md-6 px-0">
            <img
              src={img2}
              alt="Mobile Application Development"
              className={`${styles.img} ${styles.img2}`}
            />
          </div>
          <div className="col-md-6 px-0">
            <div className={styles.topContain1}>
              <p className={`${styles.headTop}`}>
                <span style={{ color: "#9951c4" }}>
                  <FaMobileAlt /> Mobile Application Development
                </span>
              </p>
              <p className={styles.headTop}>Services</p>
              <p className={styles.identityTop}>
                Your Digital Solutions Partner
              </p>
              <p className={styles.content}>
                We develop high-performance Android and iOS applications using
                Flutter, ensuring a seamless user experience across devices. Our
                mobile development team is dedicated to building feature-rich
                applications tailored to your business needs...
              </p>
            </div>
          </div>
        </div>

        {/* Middle Section */}
        <div className="container">
          <div
            className={`row ${styles.contain} flex-column-reverse flex-md-row`}
          >
            <div className={`col-md-6 ${styles.middleContain}`}>
              <p className={styles.heading}>
                <FaCogs /> Empower Your Business
              </p>
              <p className={styles.text}>
                Our web development services focus on creating websites that are
                not just visually appealing but also functional and optimized
                for performance. Similarly, our Android development team is
                dedicated to building applications that offer seamless
                experiences on mobile devices. We prioritize user-centric
                designs and employ agile methodologies to ensure timely delivery
                without compromising quality...
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={img3}
                alt="Empower Your Business"
                className={` ${styles.img3}`}
              />
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="container">
          <div className={`row ${styles.contain}`}>
            <div className="col-md-6">
              <img
                src={img4}
                alt="Custom Solutions"
                className={`${styles.img4}`}
              />
            </div>
            <div className={`col-md-6 ${styles.bottomContain}`}>
              <p className={styles.heading1}>
                <FaTools /> Custom Software Development
              </p>
              <p className={styles.text1}>
                Tailored Solutions for Your Unique Needs. We understand that
                every business is unique, which is why our development
                strategies are customized to align with your objectives. Whether
                it’s enhancing your existing web platform or creating an
                innovative Android app from scratch, our team collaborates
                closely with you to deliver tailored solutions. Our commitment
                to quality and performance ensures that your digital products
                stand out in a crowded marketplace...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebAndroidDevelopment;
