import img1 from "../../../assets/aboutUs/ino.jpg";
import img2 from "../../../assets/aboutUs/ino2.jpg";

const fakeData = [
  {
    id: 1,
    heading: 'Innovative Solutions for You',
    img: img1,
    content:
      "At the heart of our operations lies a commitment to innovation. We craft unique solutions that cater specifically to your needs, ensuring that every project reflects your vision and values. Our team harnesses creativity and expertise to transform challenges into opportunities, paving the way for your success. Together, let’s redefine what’s possible and embark on a journey of growth and transformation.",
  },
  {
    id: 2,
    heading: 'We Do with Creativity',
    img: img2,
    content:
      "We believe that technology is a powerful catalyst for change. Our mission is to empower businesses by integrating cutting-edge technology into their operations. From streamlining processes to enhancing customer experiences, we provide the tools and strategies necessary to thrive in a competitive landscape. Join us as we explore new horizons and unlock your business’s full potential.",
  },
];

export default fakeData;
