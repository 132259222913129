import React from "react";
import styles from './ChoosingUs.module.css';
import experience from '../../assets/home/experience.png';
import communication from '../../assets/home/communication.png';
import comfort from '../../assets/home/comfort.png';

const ChoosingUs = () =>{
    return(
        <div style={{backgroundColor: '#fff1e5'}}>
            <div className="container overflow-hidden">
                <p data-aos='slide-right' data-aos-offset="220" className={styles.heading}>Top three the reason why you should choosing us.</p>
                <p data-aos='slide-right' className={styles.sub_text}>Our team ensure that every solution meets the highest quality standards.</p>
                <div className={`row ${styles.sub}`}>
                    <div data-aos='fade-right' className="col-md-4">
                        <img src={experience} alt="experience"/>
                        <p className={styles.logo_head}>Experienced Team</p>
                        <p className={styles.logo_text}>Our team delivers top-notch IT solutions backed by years of experience.</p>
                    </div>

                    <div data-aos='fade' className="col-md-4">
                        <img src={communication} alt="experience"/>
                        <p className={styles.logo_head}>Client-Centered Approach</p>
                        <p className={styles.logo_text}>We tailor every solution to meet the unique needs of our clients.</p>
                    </div>

                    <div data-aos='fade-left' className="col-md-4">
                        <img src={comfort} alt="experience"/>
                        <p className={styles.logo_head}>Innovative Solutions</p>
                        <p className={styles.logo_text}>We stay ahead of the curve by implementing the latest technologies.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoosingUs;