import React from "react";
import styles from "./Real.module.css";
import improveImg from "../../assets/home/real.jpg";
import { Link } from "react-router-dom";

const Real = () => {
  return (
    <div className="mb-5 overflow-hidden">
      <div className="row g-0">
        <div className={`col-md-6 ${styles.imgDiv}`}>
          <img
            data-aos="slide-right"
            data-aos-offset="150"
            src={improveImg}
            alt=""
            className={styles.img}
          />
        </div>
        <div className="col-md-6">
          <div className={styles.text}>
            <p
              data-aos="slide-right"
              data-aos-offset="150"
              className={styles.heading}
            >
              Let’s get started
            </p>
            <p
              data-aos="slide-left"
              data-aos-offset="150"
              className={styles.subHeading}
            >
              Launch Your Business into the Digital World!
            </p>
            <p
              data-aos="zoom-in"
              data-aos-offset="150"
              className={styles.content}
            >
              At VYNX, we believe in the power of innovation and simplicity. Our
              mission is to empower business owners by removing technical and
              financial hurdles, enabling them to create their own successful
              online presence with ease. We are excited to help you on your
              journey! Discover how our tailored solutions enhance customer
              experiences and drive growth. From responsive web design to agile
              technology solutions, we bring your vision to life through
              seamless integration and impactful results.
            </p>
            <div data-aos="zoom-in" style={{ textAlign: "left" }}>
              <Link to="/contactUs">
                <button className={`btn custom_btn ${styles.btn}`}>
                  CONTACT US
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Real;
