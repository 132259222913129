import React from "react";
import { Link } from "react-router-dom";
import styles from "./Services.module.css";
import data from "./data";
import timer from "../../assets/individualService/timer.png";
import lock from "../../assets/individualService/tren.png";
import det from "../../assets/individualService/det.png";

const Services = () => {
  return (
    <div className={`container ${styles.contain} overflow-hidden pb-2`}>
      <p data-aos="slide-right" className={styles.heading}>
        Our Services
      </p>
      <div>
        <p data-aos="slide-left" className={`mx-auto ${styles.subHeading}`}>
          Modernize your work and maximize results
        </p>
      </div>

      <div className={`row ${styles.cont}`}>
        {data &&
          data.map((x) => (
            <div
              key={x.id}
              className="col-lg-4 col-md-6 mb-4 d-flex justify-content-center"
            >
              <Link to={x.to} className="text-decoration-none">
                <div
                  className={`${styles.box} user-select-none d-flex flex-column align-items-center`}
                >
                  <img src={x.icon} alt="" className={styles.img} />
                  <p className={styles.cardHeading}>{x.heading}</p>
                  <p className={styles.cardText}>{x.content}</p>
                </div>
              </Link>
            </div>
          ))}
      </div>

      <div className="row">
        <div className="col-md-4" data-aos="fade-right" data-aos-offset="150">
          <img src={timer} alt="Speed Optimization" className={styles.img1} />
          <p className={styles.logo_head}>Speed Optimization</p>
          <p className={styles.heading_content}>
            Boost your site's performance with fast load times that enhance user
            experience.
          </p>
        </div>

        <div className="col-md-4" data-aos="fade" data-aos-offset="150">
          <img src={det} alt="Marketing Strategy" className={styles.img1} />
          <p className={styles.logo_head}>Marketing Strategy</p>
          <p className={styles.heading_content}>
            Craft effective marketing strategies that resonate with your
            audience and drive results.
          </p>
        </div>

        <div className="col-md-4" data-aos="fade-left" data-aos-offset="150">
          <img src={lock} alt="Expert Team" className={styles.img1} />
          <p className={styles.logo_head}>Expert Team</p>
          <p className={styles.heading_content}>
            Work with our expert team to unlock innovative solutions that grow
            your business.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
